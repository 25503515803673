/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { login } from '../../../store/actions'
import { Store } from '../../../store/storeProvider'

const loginSchema = Yup.object().shape({
  loginName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('UserID is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  loginName: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const history = useHistory()
  const { dispatch, state: { ui: { isLoading: loading } } } = useContext(Store)

  const [error, setError] = useState('')
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const res = await dispatch(login(values))
      if (res) {
        console.log(res)
        setError(res)
        setSubmitting(false)
      } else {
        setSubmitting(false)
        history.push('/auth/verify-token')
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <img alt='Logo' src={toAbsoluteUrl('/media/fcmb/FCMB_Logo.png')} className='h-50px' />
      </div>
      {/* begin::Heading */}

      {error ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{error}</div>
        </div>
      ) : (
        null
      )}

      <Input formik={formik} name="loginName" label="UserID" placeholder='firstname.lastname' />

      <Input formik={formik} name="password" label="Password" type="password" />

      <Button buttonText='Sign In' formik={formik} loading={loading} />

    </form>
  )
}
