import React from 'react'
import { formatOptionsArray } from '../helpers'
import clsx from 'clsx'
type InputType = {
  formik: any
  label: string
  name: string
  options: any[]
  optionLabel: string
  optionValue: string
  containerStyle?: string
}

const Select = ({ formik, label, name, optionLabel, optionValue, options, containerStyle }: InputType) => {
  const formattedOptions = formatOptionsArray(options, optionLabel, optionValue)
  return (
    <section className={containerStyle}>
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>{label}</label>
        <select name={name}  {...formik.getFieldProps(name)} className={clsx(
          'form-control form-control-lg form-control-solid',
          { 'is-invalid': formik.touched[name] && formik.errors[name] },
          {
            'is-valid': formik.touched[name] && !formik.errors[name],
          }
        )}>
          <option value="">
            Please Select
          </option>
          {formattedOptions.length && formattedOptions.map((option, key) => (
            <option value={option.value} key={key}>{option.label}</option>
          ))}
        </select>

        {formik.touched[name] && formik.errors[name] && (
          <div className='fv-plugins-message-container text-danger fs-8'>
            <span role='alert'>{formik.errors[name]}</span>
          </div>
        )}
      </div>
    </section>

  )
}

export default Select