/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { verifyToken } from '../../../store/actions'
import { Store } from '../../../store/storeProvider'

const loginSchema = Yup.object().shape({
  tokenCode: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Token is required'),
})

const initialValues = {
  tokenCode: '',
}


export function VerifyToken() {
  const { dispatch, state: { auth: { authData: { staffID } } } } = useContext(Store)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      const res = await dispatch(verifyToken({ ...values, staffId: staffID }))
      if (res) {
        setLoading(false)
        setSubmitting(false)
        setError(res)
      }
    },
  })



  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <img alt='Logo' src={toAbsoluteUrl('/media/fcmb/FCMB_Logo.png')} className='h-50px' />
      </div>
      {/* begin::Heading */}

      {error ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{error}</div>
        </div>
      ) : (
        null
      )}

      <Input formik={formik} name="tokenCode" label="Token" />

      <Button buttonText='Verify Token' formik={formik} loading={loading} />

    </form>
  )
}
