import React from "react";
import DataTable from 'react-data-table-component'

type TableProps = {
  columns: any[]
  data: any[]
  title?: string
  paginationHandler?: Function
  totalItems?: number
  perPage?: number
  isLoading?: boolean
  customStyles?: any
  onSelectedRowsChange?: any
  handleClearRows?: any
}

const CustomTable = ({ columns, data, title, paginationHandler, totalItems, isLoading, customStyles = {}, onSelectedRowsChange, handleClearRows }: TableProps) => {

  if (paginationHandler) {
    const handlePageChange = (pageNumber: number) => {
      paginationHandler({pageNumber, pageSize: 10})
    };

    const handlePerRowsChange = async (pageSize: number, pageNumber: number) => {
      paginationHandler({pageNumber, pageSize})
    };

    return (
      <DataTable
        columns={columns}
        data={data}
        title={title}
        progressPending={isLoading}
        selectableRows
        pagination
        paginationServer
        paginationTotalRows={totalItems}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        customStyles={customStyles} onSelectedRowsChange={onSelectedRowsChange}
        clearSelectedRows={handleClearRows}
      />
    )
  }

  else {
    return (
      <DataTable columns={columns} data={data} title={title} selectableRows customStyles={customStyles} onSelectedRowsChange={onSelectedRowsChange}
        clearSelectedRows={handleClearRows}
      />
    )
  }

}

export default CustomTable