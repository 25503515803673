import { baseUrl } from "../../constants"
import axios from '../../config/axios.config'
import { ERROR_MESSAGE, TIME_OUT_MESSAGE } from "../../constants"
import { logOut } from "./"
import { SET_ACTIVITIES_DATA, SET_CHART_DATA } from "./actionTypes"
import { uiStartLoading, uiStopLoading } from "./uiActions"

const setActivitiesData = (payload) => {
  return {
    type: SET_ACTIVITIES_DATA,
    payload
  }
}

const setChartData = (payload) => {
  return {
    type: SET_CHART_DATA,
    payload
  }
}

const getActivitiesUrl = (role) => {

  switch (role) {
    case 'Maker':
      return 'RetailAccessValidation/GetCreatedRequestHistory';

    case 'Checker':
      return 'RetailAccessValidation/GetApprovedRequestHistory'

    case 'Retriever':
      return 'RetailAccessValidation/GetRetrievedRequestHistory'

    default:
      return null
  }

}

const getChartUrl = (user) => {

  switch (user.userRole) {
    case 'Maker':
      return `RetailAccessValidation/GetCreatedRecordHistoryPerMonth?CreatedBy=${user?.staffName}`;

    case 'Checker':
      return `RetailAccessValidation/GetApprovedRecordHistoryPerMonth?ApprovedBy=${user?.staffName}`;

    case 'Retriever':
      return `RetailAccessValidation/GetRequestedRecordHistoryPerMonth?RequestedBy=${user?.staffName}`;

    default:
      return null
  }

}


export const getActivitiesData = () => {
  return async (dispatch, state) => {
    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      dispatch(uiStartLoading())
      let url = getActivitiesUrl(state.auth.tokenData.userRole)
      const res = await axios.get(`${baseUrl}${url}?UserName=${state.auth.tokenData.staffName}`, {
        headers: {
          Authorization: `Bearer ${state.auth.tokenData.token}`
        }
      })
      if (res.status === 200 && res.data.code === "00") {
        await dispatch(setActivitiesData(res.data.data))
        dispatch(uiStopLoading())
        return null;
      }
      dispatch(uiStopLoading())
      return res.data?.description
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(logOut())
      }
      dispatch(uiStopLoading())
      if (error?.response?.data?.description) {
        return error.response.data.description
      }
      return ERROR_MESSAGE
    }
  }
}

export const getChartData = () => {
  return async (dispatch, state) => {
    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      const userData = state.auth.tokenData;
      const url = getChartUrl(userData)
      dispatch(uiStartLoading())
      const res = await axios.get(`${baseUrl}${url}`, {
        headers: {
          Authorization: `Bearer ${state.auth.tokenData.token}`
        }
      })
      if (res.status === 200 && res.data.code === "00") {
        await dispatch(setChartData(res.data.data))
        dispatch(uiStopLoading())
        return null;
      }
      dispatch(uiStopLoading())
      return res?.data?.description
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(logOut())
      }
      dispatch(uiStopLoading())
      if (error?.response?.data?.description) {
        return error.response.data.description
      }
      return ERROR_MESSAGE
    }
  }
}

