import moment from "moment"
import CryptoJS from "crypto-js"
import { DevApiCredentials } from "../constants";
const aesEcb = require("aes-ecb");


export function formatOptionsArray(options: any[], optionLabel: string, optionValue: string) {
  if (!options.length) {
    return []
  }
  return options.map((option: any) => ({ label: option[optionLabel], value: option[optionValue] }))
}


export const formatDate = (date: string, format: string) => {
  return date && moment(date).format(format || 'DD/MM/YYYY')
}


export function combineReducers(reducers: any) {
  let state = Object.keys(reducers).reduce(
    (acc, key) => ({ ...acc, [key]: reducers[key][0] }),
    {}
  );
  const dispatch = async (action: any) =>
    typeof action === "function"
      ? await action(dispatch, state)
      : Object.keys(reducers).forEach((key) => {
        return reducers[key][1](action);
      });


  return [state, dispatch];
}


export function storeAsyncData(data: any) {
  localStorage.setItem('authData', JSON.stringify(data))
}


export function getAsyncData() {
  const data = localStorage.getItem('authData')
  if (data) {
    return JSON.parse(data)
  }
}


export function isAdmin() {
  const data = getAsyncData()
  if (data?.userRole && data.userRole.toLowerCase() === 'admin') {
    return true
  }
  return false


}


export function isMaker() {
  const data = getAsyncData()
  if (data?.userRole && data.userRole.toLowerCase() === 'maker') {
    return true
  }
  return false
}


export function isChecker() {
  const data = getAsyncData()
  if (data?.userRole && data.userRole.toLowerCase() === 'checker') {
    return true
  }
  return false
}


export function isRetriever() {
  const data = getAsyncData()
  if (data?.userRole && data.userRole.toLowerCase() === 'retriever') {
    return true
  }
  return false
}




export function removeAsyncData() {
  localStorage.removeItem("authData")
}
export function capitalize(text: string) {
  const wordsArray = text.toLowerCase().split(' ')
  const capsArray = wordsArray.map((word) => {
    return word[0].toUpperCase() + word.slice(1)
  })
  return capsArray.join(' ')
}


export async function sendRequest(
  url: string,
  method: string = "GET",
  body: any,
  headers: any = {},
  token: string
) {
  return await fetch(url, {
    method: method,
    body: method.toLowerCase() === "get" ? undefined : JSON.stringify(body),
    headers: {
      "Content-Type": headers.contentType || "application/json",
      Accept: headers.accept || "application/json",
      Authorization: "Bearer " + token,
      ...headers,
    },
  });
}


export const isRetrieveFormError = (values: any) => {
  if (values.accountNumber && !values.integrifyID) return false
  else if (!values.accountNumber && values.integrifyID) return false
  else if (values.accountNumber && values.integrifyID) return false
  else return true
}


export const isReportFormError = (values: any) => {
  if (!values.startDate && !values.endDate && values.Integrify) return false
  else if (values.startDate && values.endDate && !values.Integrify) return false
  else if (values.startDate && values.endDate && values.Integrify) return false
  else return true
}


export const encrypt = (str: string) => {
  return aesEcb.encrypt(process.env.REACT_APP_AES_KEY, str);
}


export const generateHeader = () => {
  const dateToUse = new Date();
  const UTCTimestamps = dateToUse.toISOString().replace("Z", "");
  const dateInToken = UTCTimestamps.replace("T", "")
    .replace(":", "")
    .replace(":", "")
    .substring(0, UTCTimestamps.length - 7);
  const shaOneEncrypt = CryptoJS.SHA512(
    dateInToken + DevApiCredentials.client_id + DevApiCredentials.xtoken_password
  );
  const apiHeader = {
    "x-token": shaOneEncrypt.toString(CryptoJS.enc.Hex),
    Client_ID: DevApiCredentials.client_id,
    "Ocp-Apim-Subscription-Key": DevApiCredentials.subscription_key_value,
    UTCTimestamp: UTCTimestamps,
  };
  return apiHeader;
};

