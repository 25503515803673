/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItem } from './AsideMenuItem'
import { isAdmin, isChecker, isMaker, isRetriever } from '../../../../app/helpers'
export function AsideMenuMain() {
  const intl = useIntl()
  return (
    <>


      {(isMaker()) && (
        <>
          <AsideMenuItem
            to='/dashboard'
            icon='/media/icons/duotone/Design/PenAndRuller.svg'
            title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
            fontIcon='bi-app-indicator'
          />
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>MAKER</span>
            </div>
          </div>

          <AsideMenuItem
            to='/upload-request'
            icon='/media/icons/duotone/Files/File-Plus.svg'
            title='Upload Request'
            fontIcon='bi-layers'
          />
        </>
      )
      }

      {(isRetriever()) && (
        <>
          <AsideMenuItem
            to='/dashboard'
            icon='/media/icons/duotone/Design/PenAndRuller.svg'
            title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
            fontIcon='bi-app-indicator'
          />
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>RETRIEVER</span>
            </div>
          </div>
          <AsideMenuItem
            to='/retrieve-request'
            icon='/media/icons/duotone/Files/Folder-check.svg'
            title='Retrieve Request'
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='/reports'
            icon='/media/icons/duotone/Files/Export.svg'
            title='Reports'
            fontIcon='bi-layers'
          />
        </>
      )
      }

      {(isChecker()) && (
        <>
          <AsideMenuItem
            to='/dashboard'
            icon='/media/icons/duotone/Design/PenAndRuller.svg'
            title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
            fontIcon='bi-app-indicator'
          />
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>CHECKER</span>
            </div>
          </div>
          <AsideMenuItem
            to='/pending-requests'
            icon='/media/icons/duotone/Files/Folder-check.svg'
            title='Pending Request'
            fontIcon='bi-layers'
          />

        </>
      )
      }


      {(isAdmin()) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>ADMIN</span>
            </div>
          </div>

          <AsideMenuItem
            to='/users'
            icon='\media\icons\duotone\General\User.svg'
            title='Users'
            fontIcon='bi-layers'
          />
        </>
      )
      }


    </>
  )
}
