import React, {Suspense, useContext} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import { useIdleTimer } from 'react-idle-timer'
import AuthInit from './AuthInit'
import {Routes} from './routing/Routes'
import { logOut } from './store/actions'
import { Store } from './store/storeProvider'


type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  const {  dispatch } = useContext(Store)

  const handleOnIdle = () => {
    dispatch(logOut())
  }

  useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    debounce: 500,
  })


  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Routes />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
