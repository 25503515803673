import React, { FC } from "react";
import { Modal } from "react-bootstrap-v5";
import { KTSVG } from '../../_metronic/helpers'

type ModalType = {
  show: boolean,
  title: string,
  handleClose: any
  size?: "lg" | "sm" | "xl"
}
const CustomModal: FC<ModalType> = ({ title, show, handleClose, size = "lg", children }) => {
  return (
    <Modal show={show} onHide={handleClose} size={size}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
        </div>
      </Modal.Header>
      {children}
    </Modal>
  )
}

export default CustomModal