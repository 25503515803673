// Auth ActionTypes
export const SET_AUTH_DATA = "SET_AUTH_DATA"
export const SET_LOCAL_AUTH_DATA = "SET_LOCAL_AUTH_DATA"
export const RESET_APP = "RESET_APP"
export const SET_TOKEN_DATA = "SET_TOKEN_DATA"

// Retail Assets
export const SET_LOAN_ASSETS_REPORT = "SET_LOAN_ASSETS_REPORT"
export const SET_LOAN_ASSET_REQUEST = "SET_LOAN_ASSET_REQUEST"
export const SET_PENDING_LOAN_ASSETS = "SET_PENDING_LOAN_ASSETS"
export const RESET_LOAN_ASSETS = "RESET_LOAN_ASSETS"

// User
export const SET_USERS = "SET_USERS"
export const SET_USER = "SET_USER"
export const SET_ROLES = "SET_ROLES"

// stats
export const SET_ACTIVITIES_DATA = "SET_ACTIVITIES_DATA"
export const SET_CHART_DATA = "SET_CHART_DATA"

// ui
export const UI_START_LOADING = "UI_START_LOADING"
export const UI_STOP_LOADING = "UI_STOP_LOADING"