import React, { useContext, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { useFormik } from 'formik'
import Input from '../../components/Input'
import Button from '../../components/Button'
import InitialState from '../../constants/initialState'
import { GetReportSchema } from '../../constants/schema'
import { getLoanAssetsReport } from '../../store/actions'
import Swal from 'sweetalert2'
import generatePDF from '../../helpers/generatePDF'
import TableCard from '../../components/TableCard'
import { AssetsColumn } from '../../constants/columns'
import { retailAssetPdfHeader } from '../../constants/pdfHeaders'
import { Store } from '../../store/storeProvider'
import ViewRequest from './ViewRequest'
import { isReportFormError } from '../../helpers'
const Reports = () => {
  const { dispatch, state: { loanAssets } } = useContext(Store)
  const [show, setShow] = useState(false)
  const [requestObject, setRequestObject] = useState({})

  const [data, setData] = useState({})

  const formik = useFormik({
    initialValues: InitialState.getReport,
    validationSchema: GetReportSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      if (isReportFormError(values)) {
        Swal.fire(
          'Opps..',
          "Please query with either Date or Integrify ID",
          'error'
        )
      } else {
        const res = await dispatch(getLoanAssetsReport(values))
        if (res) {

          setSubmitting(false)
          Swal.fire(
            'Opps..',
            res,
            'error'
          )
        } else {
          setSubmitting(false)
          setData({ startDate: formik.values.startDate, endDate: formik.values.endDate, Integrify: formik.values.Integrify })
          Swal.fire(
            'Success',
            'Report Retrieved Successfully',
            'success'
          )
          resetForm()
        }
      }

    },
  })

  const viewRequest = (request) => {
    setRequestObject(request)
    setShow(true)
  }

  const columns = AssetsColumn(viewRequest)

  const downloadPDF = () => {
    generatePDF(loanAssets.loanAssetsReport.data, retailAssetPdfHeader)
  }

  const paginationHandler = (params) => {
    dispatch(getLoanAssetsReport(data, params))
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>REPORTS</PageTitle>
      <div className="card mb-8 p-3">
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
        >

          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            null
          )}
          <div className="row">
            <div className="col-md-3">
              <Input label='Start Date' formik={formik} name="startDate" placeholder="Start Date" type='date' showError={false} />
            </div>
            <div className="col-md-3">
              <Input label='End Date' formik={formik} name="endDate" placeholder="End Date" type='date' showError={false} />
            </div>
            <div className="col-md-3">
              <Input label='Integrify ID' formik={formik} name="Integrify" placeholder="Integrify ID" showError={false} />
            </div>
            <div className="col-md-3">
              <div className='mb-8'></div>
              <Button buttonText='Send Request' formik={formik} />
            </div>

          </div>
        </form>
      </div>


      {loanAssets?.loanAssetsReport.data &&
        <TableCard title='Asset Report' items={loanAssets?.loanAssetsReport?.data} columns={columns} exportAction={downloadPDF} totalItems={loanAssets.loanAssetsReport?.totalPageCount} paginationHandler={paginationHandler} />
      }

      <ViewRequest show={show} handleClose={() => setShow(false)} requestObject={requestObject} />
    </>
  )
}

export default Reports 