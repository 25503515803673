export const retailAssetPdfHeader = [
  {
    sN: "S/N",
    customerName: "Customer Name",
    accountNumber: "Account Number",
    integrifyID: "Integrify ID",
    collectionStaff: "Collection Staff",
    collectionStaffApproval: "Collection Staff (Approval)",
    companyName: "Employer",
    feedBack: "Feedback",
  }
]

export const usersPdfHeader = [
  {
    sN: "S/N",
    userName: "Username",
    emailAddress: "Email Address",
    staffId: "Staff ID",
  }
]