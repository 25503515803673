/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext} from 'react'
import { Store } from '../../../../app/store/storeProvider'
import { toAbsoluteUrl } from '../../../helpers'
import { logOut } from '../../../../app/store/actions'


const HeaderUserMenu: FC = () => {
  const {state: {auth: {tokenData}}, dispatch} = useContext(Store)
  
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='metronic' className="rounded-circle" />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-7'>
              {tokenData?.displayName}
           
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-8'>
              {tokenData?.userRole}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5 menu-link' onClick={()=> dispatch(logOut())}>
        <span className='menu-link px-5 bi-cursor'>
          Sign Out
        </span>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
