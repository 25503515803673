import React, { useState, useRef, useEffect, useContext } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import TableCard from '../../components/TableCard'
import { UsersColumn } from '../../constants/columns'
import AddUser from './AddUser'
import InitialStates from '../../constants/initialState'
import generatePDF from '../../helpers/generatePDF'
import { usersPdfHeader } from '../../constants/pdfHeaders'
import { getUserRoles, getUsers } from '../../store/actions'
import { Store } from '../../store/storeProvider'

const Users = () => {
  const { dispatch, state: { users: { users, roles } } } = useContext(Store)
  const hasFetchedData = useRef(false)

  const [modalVisible, setModalVisible] = useState(false)
  const [user, setUser] = useState(InitialStates.addUser)

  const editHandler = (row) => {
    setUser(row)
    setModalVisible(true)
  }
  const deleteHandler = (row) => {
    console.log(row)
  }

  const columns = UsersColumn(editHandler, deleteHandler)
  const handleClose = () => setModalVisible(false)

  const addUserModal = () => {
    setModalVisible(true)
    setUser(InitialStates.addUser)
  }

  const exportUsers = () => {
    generatePDF(users.data, usersPdfHeader, 'Users')
  }

  useEffect(() => {
    if (!hasFetchedData.current) {
      dispatch(getUsers())
      dispatch(getUserRoles())
      hasFetchedData.current = true
    }
  }, [])

  const paginationHandler = (params) => {
    dispatch(getUsers(params))
  }


  return (
    <>
      <PageTitle breadcrumbs={[]}>USERS</PageTitle>
      <TableCard columns={columns} items={users?.data} title='Users' addItemAction={addUserModal} exportAction={exportUsers} totalItems={users?.totalPageCount} paginationHandler={paginationHandler} />
      <AddUser modalVisible={modalVisible} handleClose={handleClose} user={user} roles={roles} />
    </>
  )
}

export default Users