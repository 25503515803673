/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from 'moment'
import { isChecker, isMaker, isRetriever } from '../../helpers'


const UploadedRequestActivities = ({ className, activities }) => {

  const randColors = ['warning', 'danger', 'success', 'primary', 'secondary']

  if (isMaker()) {
    return (
      <div className={`card ${className} overflow-auto`} style={{ maxHeight: '500px' }}>
        <div className='card-body'>

          <div className='d-flex flex-stack flex-wrap flex-grow-1'>
            <div>
              <span className='fw-bolder text-gray-800 d-block fs-3'>Weekly Activities</span>
            </div>

            <div className={`fw-bolder fs-3 text-primary`}>{activities ? `${activities.length} Requests` : ''}</div>
          </div>

          <section className='pt-5'>
            {/* begin::Timeline */}
            <div className='timeline-label'>
              {/* begin::Item */}
              {
                activities && activities.map((d, index) => (

                  < div className='timeline-item mb-10' key={index}>
                    {/* begin::Label */}
                    <div className='timeline-label fw-bolder text-gray-800 fs-8 me-4' > {moment(d?.createdDate).format('LT')}</div>
                    {/* end::Label */}
                    {/* begin::Badge */}
                    <div className='timeline-badge'>
                      <i className={`fa fa-genderless text-warning text-${randColors[Math.ceil(Math.random() * 6)]} fs-1`}></i>
                    </div>
                    {/* end::Badge */}
                    {/* begin::Text */}
                    <div className='fw-mormal timeline-content text-muted ps-3'>
                      Created <span className='fw-bolder text-primary'>{d?.customerName}</span> Loan Asset Request on the {moment(d?.createdDate).format('llll')}


                    </div>
                    {/* end::Text */}
                  </div>
                ))
              }
              {/* end::Item */}
            </div>
            {/* end::Timeline */}
          </section>


        </div >
        {/* end: Card Body */}
      </div >
    )
  }

  else if (isRetriever()) {
    return (
      <div className={`card ${className} overflow-auto`} style={{ maxHeight: '300px' }}>
        <div className='card-body'>

          <div className='d-flex flex-stack flex-wrap flex-grow-1'>
            <div>
              <span className='fw-bolder text-gray-800 d-block fs-3'>Weekly Activities</span>
            </div>

            <div className={`fw-bolder fs-3 text-primary`}>{activities ? `${activities.length} Requests` : ''}</div>
          </div>

          <section className='pt-5'>
            {/* begin::Timeline */}
            <div className='timeline-label'>
              {/* begin::Item */}
              {
                activities && activities.map((d, index) => (

                  < div className='timeline-item mb-10' key={index}>
                    {/* begin::Label */}
                    <div className='timeline-label fw-bolder text-gray-800 fs-8 me-4' > {moment(d?.lastModifiedDate).format('LT')}</div>
                    {/* end::Label */}
                    {/* begin::Badge */}
                    <div className='timeline-badge'>
                      <i className={`fa fa-genderless text-warning text-${randColors[Math.ceil(Math.random() * 6)]} fs-1`}></i>
                    </div>
                    {/* end::Badge */}
                    {/* begin::Text */}
                    <div className='fw-mormal timeline-content text-muted ps-3'>
                      Retrieved <span className='fw-bolder text-primary'>{d?.customerName}</span> Loan Asset Request on the {moment(d?.lastModifiedDate).format('llll')}


                    </div>
                    {/* end::Text */}
                  </div>
                ))
              }
              {/* end::Item */}
            </div>
            {/* end::Timeline */}
          </section>


        </div >
        {/* end: Card Body */}
      </div >
    )
  }

  else if (isChecker()) {
    return (
      <div className={`card ${className} overflow-auto`} style={{ maxHeight: '300px' }}>
        <div className='card-body'>

          <div className='d-flex flex-stack flex-wrap flex-grow-1'>
            <div>
              <span className='fw-bolder text-gray-800 d-block fs-3'>Weekly Activities</span>
            </div>

            <div className={`fw-bolder fs-3 text-primary`}>{activities ? `${activities.length} Requests` : ''}</div>
          </div>

          <section className='pt-5'>
            {/* begin::Timeline */}
            <div className='timeline-label'>
              {/* begin::Item */}
              {
                activities && activities.map((d, index) => (

                  < div className='timeline-item mb-10' key={index}>
                    {/* begin::Label */}
                    <div className='timeline-label fw-bolder text-gray-800 fs-8 me-4' > {moment(d?.approvalDate).format('LT')}</div>
                    {/* end::Label */}
                    {/* begin::Badge */}
                    <div className='timeline-badge'>
                      <i className={`fa fa-genderless text-warning text-${randColors[Math.ceil(Math.random() * 6)]} fs-1`}></i>
                    </div>
                    {/* end::Badge */}
                    {/* begin::Text */}
                    <div className='fw-mormal timeline-content text-muted ps-3'>
                      <span className={d?.approvalStatus === "Approved" ? 'text-success' : 'text-danger'}> {d?.approvalStatus} </span><span className='fw-bolder text-dark'>{d?.customerName}</span> Loan Asset Request on the {moment(d?.createdDate).format('llll')}

                    </div>
                    {/* end::Text */}
                  </div>
                ))
              }
              {/* end::Item */}
            </div>
            {/* end::Timeline */}
          </section>


        </div >
        {/* end: Card Body */}
      </div >
    )
  }



}

export default UploadedRequestActivities
