import { RESET_APP, SET_AUTH_DATA, SET_LOCAL_AUTH_DATA, SET_TOKEN_DATA } from "../actions/actionTypes";
import { storeAsyncData } from "../../helpers";

const initialState = {
  authData: {},
  tokenData: {},
  isAuthenticated: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_DATA:
      
      return {
        ...state,
        authData: { ...action.payload },
      };
    case SET_TOKEN_DATA:
      storeAsyncData(action.payload)
      return {
        ...state,
        authData: { ...action.payload },
        tokenData: { ...action.payload },
        isAuthenticated: true
      };

    case SET_LOCAL_AUTH_DATA:
      return {
        ...state,
        tokenData: { ...action.payload },
        isAuthenticated: true
      };
    case RESET_APP:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const authReducer = {
reducer, initialState
}
export default authReducer;
