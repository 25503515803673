import {  useRef, useEffect, useState, useContext, } from 'react'

import { LayoutSplashScreen } from '../_metronic/layout/core'
import { getAsyncData } from './helpers'
import {setLocalAuthData} from './store/actions'
import { Store } from './store/storeProvider'

const AuthInit = (props) => {
  const didRequest = useRef(false)
  const {dispatch} = useContext(Store)
  
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  useEffect(() => {
    const data = getAsyncData()
    const requestUser = () => {
      try {
        if (!didRequest.current) {
          if (data) {
            dispatch(setLocalAuthData(data))
          }
        }
      } catch (error) {
        if (!didRequest.current) {
        }
      } finally {
          setShowSplashScreen(false)
        }
      return () => (didRequest.current = true)
    }
    if (data?.staffName) {
      requestUser()
    } else {
      setShowSplashScreen(false)
    }
  
  }, [])
  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>

}



export default AuthInit
