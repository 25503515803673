export const feedbackOptions = [
  { label: "Confirmed Ok", value: "confirmed" },
  { label: "Not Confirmed Ok", value: "notConfirmed" }
]


export const roleOptions = [
  { label: "Admin", value: "admin" },
  { label: "Checker", value: "checker" },
  { label: "Maker", value: "maker" },
  { label: "Retriever", value: "retriever" }
]


export const baseUrl = process.env.REACT_APP_API_URL


export const DevApiCredentials = {
  subscription_key_value: process.env.REACT_APP_subscription_key_value,
  xtoken_password: process.env.REACT_APP_xtoken_password,
  client_id: process.env.REACT_APP_client_id,
};


export const ERROR_MESSAGE = "Something went wrong! Please try again later";
export const TIME_OUT_MESSAGE = "Please check your Internet Connection"


export const months = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec'
}


export const pageNumber = 1
export const pageSize = 10

