import React, { useContext, useState } from 'react'
import { useFormik } from 'formik'
import Input from '../../components/Input'
import Select from '../../components/Select'
import Button from '../../components/Button'
import { addUser, updateUserRole } from '../../store/actions'
import Swal from 'sweetalert2'
import { AddUserSchema, } from '../../constants/schema'
import CustomModal from '../../components/CustomModal'
import { Store } from '../../store/storeProvider'

const AddUser = ({ modalVisible, handleClose, user, roles }) => {
  const { dispatch } = useContext(Store)
  const [disabled,] = useState(user.userName ? true : false)
  const formik = useFormik({
    initialValues: user,
    validationSchema: AddUserSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      let res;
      if (user?.userName) {
        res = await dispatch(updateUserRole({ staffId: values.staffId, roleId: parseInt(values.roleId) }))
      } else {
        res = await dispatch(addUser(values))
      }
      if (res) {
        setSubmitting(false)
        Swal.fire(
          'Opps..',
          res,
          'error'
        )
      } else {
        setSubmitting(false)
        resetForm()
        handleClose()
        Swal.fire(
          'Success',
          user.userName ? 'User Role Update Successfully' : 'User Added Successfully',
          'success'
        )
      }
    },
  })


  return (
    <>
      <CustomModal title='Add User' show={modalVisible} handleClose={handleClose}  >
        <div className='p-6'>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              null
            )}

            <div className='row'>
              <Input formik={formik} name="userName" label="Username" containerStyle='col-md-6' disabled={disabled} />
              <Input formik={formik} name="emailAddress" label="Email" containerStyle='col-md-6' disabled={disabled} />
            </div>

            <div className='row'>
              <Input formik={formik} name="staffId" label="Staff Id" containerStyle='col-md-6' disabled={disabled} />
              <Select formik={formik} name="roleId" label="Role" optionLabel='roleName' optionValue='id' options={roles} containerStyle='col-md-6' />
            </div>

            <div className='d-flex justify-content-end'>
              <Button buttonText={user.userName ? 'Update User' : 'AddUser'} formik={formik} styles='btn-sm' containerStyles='me-2' />
              <Button buttonText='Cancel' formik={formik} styles='btn-danger btn-sm' type='button' buttonAction={handleClose} />
            </div>
          </form>
        </div>
      </CustomModal>
    </>
  )
}

export default AddUser