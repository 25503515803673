/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {VerifyToken} from './components/VerifyToken'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

function AuthPage() {
  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/progress-hd.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Route path='/auth/login' component={Login} />
            <Route path='/auth/verify-token' component={VerifyToken} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  )
}

export default AuthPage
