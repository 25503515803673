import { months } from "../../constants";
import { SET_CHART_DATA, SET_ACTIVITIES_DATA, RESET_APP } from "../actions/actionTypes";

const initialState = {
  activitiesData: [],
  totalRequest: 0,
  chartData: {
    y: [],
    x: []
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHART_DATA:
      return {
        ...state,
        chartData: {x: action.payload.map((d) => months[d.month]),
          y: action.payload.map((d) => d.count)
        },
        totalRequest: action.payload.reduce((acc, curr)=> acc + 1, 0)
      };
      case SET_ACTIVITIES_DATA:
      return {
        ...state,
        activitiesData: [...action.payload]
      };
      case RESET_APP:
        return {
          ...initialState,
        };
    default:
      return state;
  }
};

const statsReducer = {reducer, initialState}

export default statsReducer;
