import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { isAdmin, isChecker, isMaker, isRetriever } from '../helpers'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import UsersPage from '../pages/Users'
import UploadRequestPage from '../pages/LoanAssets/UploadAsset'
import RetrieveRequestPage from "../pages/LoanAssets/RetrieveAssets"
import PendingAssetRequests from '../pages/LoanAssets/PendingAssetRequests'
import ReportsPage from '../pages/LoanAssets/AssetReport'
import { Store } from '../store/storeProvider'
import { logOut } from '../store/actions'

export function PrivateRoutes() {
  const { dispatch } = useContext(Store)

  // return (
  //   <Switch>
  //     <Route path='/dashboard' component={DashboardWrapper} />
  //     <Route path='/upload-request' component={UploadRequestPage} />
  //     <Route path='/retrieve-request' component={RetrieveRequestPage} />
  //     <Route path='/pending-requests' component={PendingAssetRequests} />
  //     <Route path='/reports' component={ReportsPage} />
  //     <Route path='/users' component={UsersPage} />
  //     <Redirect from='/auth' to='/dashboard' />
  //     <Redirect exact from='/' to='/dashboard' />
  //     <Redirect to='error/404' />
  //   </Switch>
  // )

  if (isAdmin()) {
    return (
      <Switch>
        <Route path='/users' component={UsersPage} />
        <Redirect from='/auth' to='/users' />
        <Redirect exact from='/' to='/users' />
        <Redirect to='error/404' />
      </Switch>
    )
  } else if (isChecker()) {
    return (
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/pending-requests' component={PendingAssetRequests} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    )
  }
  else if (isMaker()) {
    return (
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/upload-request' component={UploadRequestPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    )
  }

  else if (isRetriever()) {
    return (
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/retrieve-request' component={RetrieveRequestPage} />
        <Route path='/reports' component={ReportsPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    )
  }
  else {
    dispatch(logOut())
  }

}
