/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import UploadedRequestsActivities from './UploadedRequestsActivities'
import UploadedRequestBarChat from './UploadedRequestBarChat'
import { getActivitiesData, getChartData } from '../../store/actions'
import { Store } from '../../store/storeProvider'

const DashboardWrapper = () => {
  const hasFetchedData = useRef(false)
  const { dispatch, state: { stats: { activitiesData: activities, chartData, totalRequest } } } = useContext(Store)

  useEffect(() => {
    if (!hasFetchedData.current) {
      dispatch(getActivitiesData())
      dispatch(getChartData())
      hasFetchedData.current = true
    }
    return () => false
  }, [])


  return (
    <>
      <PageTitle breadcrumbs={[]}>DASHBOARD</PageTitle>
      {/* begin::Row */}
      <div className='row'>

        <div className='col-xxl-6'>
          <UploadedRequestsActivities className='card-xxl-stretch' activities={activities} />
        </div>
        <div className='col-xxl-6'>
          <UploadedRequestBarChat chartData={chartData} totalRequest={totalRequest} className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='175px' />
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}


export { DashboardWrapper }
