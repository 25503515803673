import { KTSVG } from '../../_metronic/helpers'
export const CheckerRequestsColumn = (approveRequest: any, declineRequest: any, viewRequest: any) => {
  return [
    {
      name: 'Employer',
      selector: (row: any) => row['companyName'],
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: 'Customer Name',
      selector: (row: any) => row['customerName'],
      sortable: true,
      grow: 3,
      wrap: true,
    },
    {
      name: 'Account Number',
      selector: (row: any) => row['accountNumber'],
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: 'Integrify ID',
      selector: (row: any) => row['integrifyID'],
      sortable: true,
      grow: 2,
      wrap: true,
    },

    {
      name: 'Feedback',
      selector: (row: any) => row['feedBack'],
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: 'Approval Status',
      selector: (row: any) => row['approvalStatus'],
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: 'Actions',
      cell: (row: any) => (
        <div className='d-flex justify-content-end flex-shrink-0'>
          <span
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => viewRequest(row)}
          >
            <i className="fas fa-eye"></i>

          </span>
          <span
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => approveRequest(row)}
          >
            <i className="fas fa-check"></i>
          </span>
          <span onClick={() => declineRequest(row)}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          >
            <i className="fas fa-times"></i>
          </span>
        </div>
      ),
    },
  ]
}

export const AssetsColumn = (viewRequest: any) => {
  return [
    {
      name: 'Employer',
      selector: (row: any) => row['companyName'],
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: 'Customer Name',
      selector: (row: any) => row['customerName'],
      sortable: true,
      grow: 3,
      wrap: true,
    },
    {
      name: 'Account Number',
      selector: (row: any) => row['accountNumber'],
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: 'Integrify ID',
      selector: (row: any) => row['integrifyID'],
      sortable: true,
      grow: 2,
      wrap: true,
    },

    {
      name: 'Feedback',
      selector: (row: any) => row['feedBack'],
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: 'Approval Status',
      selector: (row: any) => row['approvalStatus'],
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      name: "Actions",
      cell: (row: any) =>
      (
        <div className='d-flex justify-content-end flex-shrink-0'>
          <span
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => viewRequest(row)}
          >
            <i className="fas fa-eye"></i>

          </span>
        </div>

      )
    }
  ]
}

export const UsersColumn = (editHandler: any, deleteHandler: any) => {
  return [
    {
      name: 'Username',
      selector: (row: any) => row['userName'],
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: any) => row['emailAddress'],
      sortable: true,
    },
    {
      name: 'Staff Id',
      selector: (row: any) => row['staffId'],
      sortable: true,
    },

    {
      name: 'Role',
      selector: (row: any) => row['roleName'],
      sortable: true,
    },

    {
      name: "Actions",
      cell: (row: any) =>
      (
        <div className='d-flex justify-content-end flex-shrink-0'>
          <span
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => editHandler(row)}
          >
            <KTSVG
              path='/media/icons/duotone/Communication/Write.svg'
              className='svg-icon-3'
            />
          </span>
          {/* <span onClick={() => deleteHandler(row)}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
          >
            <KTSVG path='/media/icons/duotone/General/Trash.svg' className='svg-icon-3' />
          </span> */}
        </div>

      )
    }

  ]
}