import React from 'react'
type InputType = {
  formik?: any
  loading?: boolean
  type?: 'button' | 'submit'
  buttonText: string,
  styles?: string
  containerStyles?: string
  buttonAction?: any
}

const Button = ({ formik, loading, type = 'submit', buttonText, styles, containerStyles, buttonAction }: InputType) => {
  if(type === 'submit'){
    return (
      <div className={`text-center ${containerStyles}`}>
        <button
          type={type}
          className={`btn btn-lg btn-primary w-100 mb-5 ${styles}`}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{buttonText}</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    )
  }
  return (
    <div className={`text-center ${containerStyles}`}>
      <button
        type={type}
        className={`btn btn-lg btn-primary w-100 mb-5 ${styles}`}
        onClick={buttonAction}
      >
        <span className='indicator-label'>{buttonText}</span>
      </button>
    </div>
  )
}

export default Button