import { RESET_APP, SET_PENDING_LOAN_ASSETS, SET_LOAN_ASSETS_REPORT, SET_LOAN_ASSET_REQUEST } from "../actions/actionTypes";

const initialState = {
  pendingLoanAssets: undefined,
  loanAssetsReport: [],
  loanAssetRequest: [],
  paginationData: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PENDING_LOAN_ASSETS:
      return {
        ...state,
        pendingLoanAssets: action.payload
      };

      case SET_LOAN_ASSET_REQUEST:
        return {
          ...state,
          loanAssetRequest: action.payload
        };
      case SET_LOAN_ASSETS_REPORT:
      return {
        ...state,
        loanAssetsReport: action.payload
      };

    case RESET_APP:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const loanAssetsReducer= {
  reducer, initialState
}
export default loanAssetsReducer;
