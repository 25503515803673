import React, { useState, useRef, useEffect, useContext } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import TableCard from '../../components/TableCard'
import { CheckerRequestsColumn } from '../../constants/columns'
import CustomModal from '../../components/CustomModal'
import Button from '../../components/Button'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import { DeclineRequestSchema } from '../../constants/schema'
import TextArea from '../../components/TextArea'
import { approveRequest, getPendingLoanRequests } from '../../store/actions'
import { Store } from '../../store/storeProvider'
import generatePDF from '../../helpers/generatePDF'
import { retailAssetPdfHeader } from '../../constants/pdfHeaders'
import ViewRequest from './ViewRequest'


const PendingLoanAssets = () => {
  const hasFetchedPenidngLoanAssets = useRef(false)
  const { dispatch, state: { loanAssets } } = useContext(Store)
  const [modalVisible, setModalVisible] = useState(false)
  const [approvalStatus, setApprovalType] = useState('')
  const [requestId, setRequestId] = useState('')
  const [show, setShow] = useState(false)
  const [requestObject, setRequestObject] = useState({})


  const approve = async (row) => {
    setModalVisible(true)
    setApprovalType('Approved')
    setRequestId(row.id)
  }

  const decline = (row) => {
    setModalVisible(true)
    setRequestId(row.id)
    setApprovalType('Rejected')
  }

  const viewRequest = (request) => {
    setRequestObject(request)
    setShow(true)
  }

  const columns = CheckerRequestsColumn(approve, decline, viewRequest)
  const handleClose = () => setModalVisible(false)

  const exportPendingLoanAssets = () => {
    generatePDF(loanAssets.pendingLoanAssets.data, retailAssetPdfHeader)
  }

  const formik = useFormik({
    initialValues: { approvalReason: "" },
    validationSchema: DeclineRequestSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      const confirm = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      })
      if (confirm.isConfirmed) {
     
        const res = await dispatch(approveRequest({ id: requestId, approvalStatus, ...values }))
        if (res) {
          Swal.fire(
            'Opps..',
            res,
            'error'
          )
     
          setModalVisible(false)
        } else {
          Swal.fire(
            'Success',
            `Request ${approvalStatus === 'Approved' ? 'Approved' : 'Declined'} Successfully`,
            'success'
          )
         
          resetForm()
          setModalVisible(false)
        }
      }
    },
  })

  useEffect(() => {
    if (!hasFetchedPenidngLoanAssets.current) {
      dispatch(getPendingLoanRequests())
      hasFetchedPenidngLoanAssets.current = true
    }
  }, [])

  const paginationHandler = (params)=>{
    dispatch(getPendingLoanRequests(params))
  }




  return (
    <>
      <PageTitle breadcrumbs={[]}>PENDING REQUESTS</PageTitle>
      <TableCard columns={columns} items={loanAssets.pendingLoanAssets?.data} title='Requests' exportAction={exportPendingLoanAssets} totalItems={loanAssets.pendingLoanAssets?.totalPageCount} paginationHandler={paginationHandler} />
      <CustomModal title={`${approvalStatus === 'Approved' ? 'Approve' : 'Decline'} Request`} size='sm' show={modalVisible} handleClose={handleClose}  >
        <div className='p-6'>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
          >

            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              null
            )}
            <TextArea formik={formik} name="approvalReason" label="Reason" />
            <div className='d-flex justify-content-end'>
              <Button buttonText={`${approvalStatus === 'Approved' ? 'Approve' : 'Decline'} Request`} formik={formik} styles='btn-sm' containerStyles='me-2' />
              <Button buttonText='Cancel' formik={formik} styles='btn-danger btn-sm' type='button' buttonAction={handleClose} />
            </div>
          </form>
        </div>
      </CustomModal>
      <ViewRequest show={show} handleClose={() => setShow(false)} requestObject={requestObject} />
    </>
  )
}

export default PendingLoanAssets