import React, { useState, useContext } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { useFormik } from 'formik'
import InitialState from '../../constants/initialState'
import Input from '../../components/Input'
import Button from '../../components/Button'
import { getLoanAssetsRequest } from '../../store/actions'
import Swal from 'sweetalert2'
import { GetRequestSchema } from '../../constants/schema'
import generatePDF from '../../helpers/generatePDF'
import TableCard from '../../components/TableCard'
import { AssetsColumn } from '../../constants/columns'
import { retailAssetPdfHeader } from '../../constants/pdfHeaders'
import { Store } from '../../store/storeProvider'
import ViewRequest from './ViewRequest'
import { isRetrieveFormError } from '../../helpers'
const UploadRequest = () => {

  const { dispatch, state: { loanAssets } } = useContext(Store)

  const [data, setData] = useState({})
  const [show, setShow] = useState(false)
  const [requestObject, setRequestObject] = useState({})


  const formik = useFormik({
    initialValues: InitialState.sendRequest,
    validationSchema: GetRequestSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      if (isRetrieveFormError(values)) {
        Swal.fire(
          'Opps..',
          "Please query with either Account Number or Integrify ID",
          'error'
        )
      } else {
        const res = await dispatch(getLoanAssetsRequest(values))
        if (res) {

          setSubmitting(false)
          Swal.fire(
            'Opps..',
            res,
            'error'
          )
        } else {

          setSubmitting(false)
          setData({ accountNumber: formik.values.accountNumber, integrifyID: formik.values.integrifyID })
          Swal.fire(
            'Success',
            'Request Retrieved Successfully',
            'success'
          )
          resetForm()
        }
      }


    },
  })

  const viewRequest = (request) => {
    setRequestObject(request)
    setShow(true)
  }

  const columns = AssetsColumn(viewRequest)

  const downloadPDF = () => {
    generatePDF(loanAssets.loanAssetRequest.data, retailAssetPdfHeader)
  }

  const paginationHandler = (params) => {
    dispatch(getLoanAssetsRequest(data, params))
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>RETRIEVE ASSET</PageTitle>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <div className='w-lg-400px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
          >

            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              null
            )}
            <Input formik={formik} name="accountNumber" label="Account Number" showError={false} />
            <Input formik={formik} name="integrifyID" label="Integrify ID" showError={false} />
            <Button buttonText='Retrieve Asset' formik={formik} />
          </form>
        </div>
      </div>

      {loanAssets?.loanAssetRequest?.data &&
        <TableCard title='Requests' columns={columns} exportAction={downloadPDF} items={loanAssets.loanAssetRequest?.data} totalItems={loanAssets.loanAssetRequest?.totalPageCount} paginationHandler={paginationHandler} />
      }

      <ViewRequest show={show} handleClose={() => setShow(false)} requestObject={requestObject} />
    </>
  )
}

export default UploadRequest