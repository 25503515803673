import React from 'react'
import clsx from 'clsx'
type TextAreaType = {
  formik: any
  label: string
  name: string
}

const TextArea = ({ formik, label, name }: TextAreaType) => {
  return (
    <div className='fv-row mb-10'>
      <label className='form-label fs-6 fw-bolder text-dark'>{label}</label>
      <textarea
        // placeholder={label}
        {...formik.getFieldProps(name)}
        className={clsx(
          'form-control form-control-lg form-control-solid',
          { 'is-invalid': formik.touched[name] && formik.errors[name] },
          {
            'is-valid': formik.touched[name] && !formik.errors[name],
          }
        )}
        name={name}
        autoComplete='off'
      />
      {formik.touched[name] && formik.errors[name] && (
        <div className='fv-plugins-message-container text-danger fs-8'>
          <span role='alert'>{formik.errors[name]}</span>
        </div>
      )}
    </div>
  )
}

export default TextArea