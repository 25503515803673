import axios from "axios";
import { generateHeader } from "../helpers/index";
const Axios = axios.create({
  headers: {
    "X-Frame-Options": "DENY",
    "X-Content-Type-Options": "nosniff",
    "Cross-Origin-Opener-Policy": "same-origin",
    "Content-Security-Policy": "frame-ancestors 'self' X-Frame-Options: DENY",
  },
});


Axios.interceptors.request.use(
  (axiosConfig) => {
    if (!navigator.onLine) {
      throw new Error("Please check your Internet Connection");
    }
    const headers = generateHeader();
    axiosConfig.headers["x-token"] = headers["x-token"];
    axiosConfig.headers["Ocp-Apim-Subscription-Key"] = headers["Ocp-Apim-Subscription-Key"];
    axiosConfig.headers["Ocp-Apim-Trace"] = true;
    axiosConfig.headers.UTCTimestamp = headers.UTCTimestamp;
    axiosConfig.headers.Client_ID = headers.Client_ID;
    return axiosConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Axios;



