import { UI_START_LOADING, UI_STOP_LOADING } from './actionTypes'
export const uiStartLoading = () => {
  return async (dispatch) => {
    dispatch({
      type: UI_START_LOADING
    })
  }
}

export const uiStopLoading = () => {
  return async (dispatch) => {
    dispatch({
      type: UI_STOP_LOADING
    })
  }
}