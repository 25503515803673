import React, { FC } from 'react'

import CustomTable from './CustomTable'
import { KTSVG } from '../../_metronic/helpers'
import { capitalize } from '../helpers'
type TableCardType = {
  items: any[]
  columns: any[]
  exportAction?: React.MouseEventHandler<HTMLDivElement>
  addItemAction?: React.MouseEventHandler<HTMLDivElement>
  title: string
  isLoading?: boolean
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
  resetTableAction?: Function
  paginationHandler?: Function
  totalItems?: any
}

const TableCard: FC<TableCardType> = ({ items, exportAction, title, columns, isLoading, totalItems, paginationHandler, addItemAction }) => {


  const capitalizedTitle = capitalize(title)

  return (
    <div className="card">
      <div className="card-header border-0 pt-5 border">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">{`${capitalizedTitle} List`}</span>
          {/* <span className="text-muted mt-1 fw-bold fs-7">{meta?.totalItems} {`${lowerCaseTitle}`}</span> */}
        </h3>
        <div className='d-flex'>
          {addItemAction && (<div
            className="card-toolbar me-4"
            onClick={addItemAction}
          >
            <span
              className="btn btn-sm btn-light-primary"
            >
              Add
              <KTSVG path='\media\icons\duotone\General\User.svg' className="ms-2 svg-icon-3" />
            </span>
          </div>)}
          {exportAction && (<div
            className="card-toolbar"
            onClick={exportAction}
          >
            <span
              className="btn btn-sm btn-light-primary"
            >
              <KTSVG path="/media/icons/duotone/Files/Export.svg" className="svg-icon-3" />
              Export
            </span>
          </div>)}
        </div>


      </div>

      <div className="card-body py-3">
        <CustomTable isLoading={isLoading} columns={columns} data={items} paginationHandler={paginationHandler} totalItems={totalItems} />
      </div>
    </div>
  )
}

export default TableCard
