import { UI_START_LOADING, UI_STOP_LOADING, RESET_APP } from "../actions/actionTypes";

const initialState = {
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UI_START_LOADING:
      return {
        isLoading: true
      };
      case UI_STOP_LOADING:
      return {
        isLoading: false
      };
      case RESET_APP:
        return {
          ...initialState,
        };
    default:
      return state;
  }
};

const uiReducer = {
  reducer, initialState
}
export default uiReducer;
