import React from 'react'
import CustomModal from '../../components/CustomModal'
import moment from 'moment'

const ObjectPair = ({ name, value }) => (
  <section>
    <h6>{name}</h6>
    <p>{value}</p>
  </section>
)

export default function ViewRequest({ requestObject, show, handleClose }) {
  return (
    <CustomModal title={`${requestObject?.customerName} Request`} show={show} handleClose={handleClose} size="md">
      <section className='p-6'>
        <ul>
          <li>
            <ObjectPair name="Date of Entry" value={moment(requestObject?.createdDate).format('MMMM Do YYYY, h:mm:ss a')} />
          </li>
          <li>
            <ObjectPair name="Employer" value={requestObject?.companyName} />
          </li>
          <li>
            <ObjectPair name="Account Number" value={requestObject?.accountNumber} />
          </li>
          <li>
            <ObjectPair name="Customer Name" value={requestObject?.customerName} />
          </li>
          <li>
            <ObjectPair name="Integrify ID" value={requestObject?.integrifyID} />
          </li>
          <li>
            <ObjectPair name="Collection Staff" value={requestObject?.collectionStaff} />
          </li>
          <li>
            <ObjectPair name="Collection Staff (Approval)" value={requestObject?.collectionStaffApproval} />
          </li>

          <li>
            <ObjectPair name="Approval Status" value={requestObject?.approvalStatus} />
          </li>

          {requestObject?.approvalReason && <li>
            <ObjectPair name="Approval Reason" value={requestObject?.approvalReason} />
          </li>
          }

          {requestObject?.approvedBy && <li>
            <ObjectPair name="Approved By" value={requestObject?.approvedBy} />
          </li>}

          {requestObject?.lastModifiedDate && <li>
            <ObjectPair name="Date of Approval" value={moment(requestObject?.lastModifiedDate).format('MMMM Do YYYY, h:mm:ss a')} />
          </li>
          }

          <li>
            <ObjectPair name="Feedback" value={requestObject?.feedBack} />
          </li>

          {requestObject?.otherFeedback && <li>
            <ObjectPair name="Additional Comment" value={requestObject?.otherFeedback} />
          </li>}

        </ul>
      </section>

    </CustomModal>
  )
}
