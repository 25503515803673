import { SET_USERS, SET_ROLES, RESET_APP } from "../actions/actionTypes";

const initialState = {
  users: undefined,
  roles: [],
  paginationData: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.payload
      };
      case SET_ROLES:
        return {
          ...state,
          roles: action.payload
        };
      case RESET_APP:
        return {
          ...initialState,
        };
    default:
      return state;
  }
};

const usersReducer = {
  reducer, initialState
}
export default usersReducer ;
