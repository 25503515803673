import React from 'react'
import clsx from 'clsx'
type InputType = {
  formik?: any
  label?: string
  name: string
  placeholder?: string
  type?: string,
  containerStyle?: string
  disabled?: boolean,
  showError?: boolean
}

const Input = ({ formik, label, name, placeholder, type = "text", containerStyle, disabled = false, showError = true }: InputType) => {
  return (
    <section className={containerStyle}>
      <div className='fv-row mb-10'>
        {label && <label className='form-label fs-6 fw-bolder text-dark'>{label}</label>}
        <input
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          {...formik.getFieldProps(name)}
          className={clsx(
            'form-control form-control-lg form-control-solid',

            showError && { 'is-invalid': formik.touched[name] && formik.errors[name] },

            showError &&
            {
              'is-valid': formik.touched[name] && !formik.errors[name],
            }

          )}
          name={name}
          autoComplete='off'
        />
        {formik.touched[name] && formik.errors[name] && (
          <div className='fv-plugins-message-container text-danger fs-8'>
            <span role='alert'>{formik.errors[name]}</span>
          </div>
        )}
      </div>
    </section>

  )
}

export default Input