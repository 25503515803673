import React from 'react'
import { combineReducers } from "../helpers/";
import usersReducer from './reducers/users';
import authReducer from './reducers/auth';
import statsReducer from './reducers/statsReducer';
import loanAssetsReducer from './reducers/loanAssetsReducer';
import uiReducer from './reducers/uiReducer';

export const Store = React.createContext()


export default function StoreProvider(props) {
  const [state, dispatch] = combineReducers({
    auth: React.useReducer(authReducer.reducer, authReducer.initialState),
    users: React.useReducer(usersReducer.reducer, usersReducer.initialState),
    stats: React.useReducer(statsReducer.reducer, statsReducer.initialState),
    loanAssets: React.useReducer(loanAssetsReducer.reducer, loanAssetsReducer.initialState),
    ui: React.useReducer(uiReducer.reducer, uiReducer.initialState),
  })

  return (
    <Store.Provider value={{ state, dispatch }}>
      {props.children}
    </Store.Provider>
  )
}