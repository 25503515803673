const InitialStates = {
  login: {
    email: '',
    password: '',
    token: ''
  },
  uploadRequest: {
    companyName: '',
    accountNumber: 0,
    integrifyID: '',
    collectionStaff: '',
    collectionStaffApproval: '',
    feedBack: '',
    otherFeedback: '',
    customerName: ''
  },
  sendRequest: {
    accountNumber: '',
    integrifyID: ''
  },
  getReport: {
    startDate: '',
    endDate: '',
    Integrify: ''
  },
  addUser: {
    userName: "",
    emailAddress: "",
    roleId: "",
    staffId: ""
  }
}

export default InitialStates