import React, { useContext } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { useFormik } from 'formik'
import InitialState from '../../constants/initialState'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Select from '../../components/Select'
import TextArea from '../../components/TextArea'
import { feedbackOptions } from '../../constants'
import { uploadLoanAsset } from '../../store/actions'
import Swal from 'sweetalert2'
import { UploadRequestSchema } from '../../constants/schema'
import { Store } from '../../store/storeProvider'

const UploadRequest = () => {
  const {dispatch} = useContext(Store)

  const formik = useFormik({
    initialValues: InitialState.uploadRequest,
    validationSchema: UploadRequestSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      const res = await dispatch(uploadLoanAsset(values))
      if (res) {
        setSubmitting(false)
        Swal.fire(
          'Opps..',
          res,
          'error'
        )
      } else {
        setSubmitting(false)
        Swal.fire(
          'Success',
          'Request Uploaded',
          'success'
        )
        resetForm()
      }
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>UPLOAD REQUEST</PageTitle>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <div className='w-lg-800px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
          >

            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              null
            )}


            <section className='row'>
              <section className='col-md-6'>
                <Input formik={formik} name="companyName" label="Employer" />
              </section>
              <section className='col-md-6'>
                <Input formik={formik} name="customerName" label="Customer Name" />
              </section>

            </section>

            <div className="row">
              <div className="col-md-6">
                <Input formik={formik} name="integrifyID" label="Integrify ID" />
              </div>
              <div className="col-md-6">
                <Input formik={formik} name="accountNumber" label="Account Number" />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <Input formik={formik} name="collectionStaff" label="Collection Staff" />
              </div>
              <div className="col-md-6">
                <Input formik={formik} name="collectionStaffApproval" label="Collection Staff (Approval)" />
              </div>
            </div>
            <Select formik={formik} name="feedBack" label="Feedback" optionLabel='label' optionValue='key' options={feedbackOptions} />

            <TextArea formik={formik} name="otherFeedback" label='Additional Comment' />


            <Button buttonText='Send Request' formik={formik} />

          </form>
        </div>
      </div>
    </>
  )
}

export default UploadRequest