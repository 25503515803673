import * as Yup from 'yup'
export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

export const UploadRequestSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Employer is required'),
  customerName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Customer Name is required'),
  accountNumber: Yup.string()
    .min(10, 'Invalid Account Number')
    .max(10, 'Invalid Account Number')
    .required('Account Number is required'),
  integrifyID: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Integrify ID is required'),
  collectionStaff: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Collection Staff is required'),
  collectionStaffApproval: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Collection Staff (Approval) is required'),
  feedBack: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Feedback is required'),
})


export const GetRequestSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .min(10, 'Account Number must be equal to 10')
    .max(10, 'Account Number must be equal to 10'),
  integrifyID: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
})


export const GetReportSchema = Yup.object().shape({
  Integrify: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
})

export const AddUserSchema = Yup.object().shape({
  userName: Yup.string()
    .required('Username is required'),
  emailAddress: Yup.string()
    .email('Wrong Email Format')
    .required('EndDate is required'),
  roleId: Yup.string()
    .required('Role is required'),
  staffId: Yup.string()
    .required('Staff ID is required'),
})

export const DeclineRequestSchema = Yup.object().shape({
  approvalReason: Yup.string()
    .required('Reason is required'),
})

export const SearchSchema = Yup.object().shape({
  search: Yup.string().required('Search Field is required'),
})