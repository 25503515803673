import axios from '../../config/axios.config'
import { RESET_LOAN_ASSETS, SET_LOAN_ASSETS_REPORT, SET_LOAN_ASSET_REQUEST, SET_PENDING_LOAN_ASSETS } from "./actionTypes"
import { baseUrl } from "../../constants"
import { ERROR_MESSAGE, TIME_OUT_MESSAGE, pageNumber, pageSize } from "../../constants"
import { uiStartLoading, uiStopLoading, logOut } from "."



const setPendingLoanAssets = (payload) => {
  return {
    type: SET_PENDING_LOAN_ASSETS,
    payload
  }
}

const setLoanAssetsReport = (payload) => {
  return {
    type: SET_LOAN_ASSETS_REPORT,
    payload
  }
}

const setLoanAssetRequest = (payload) => {
  return {
    type: SET_LOAN_ASSET_REQUEST,
    payload
  }
}

export const resetLoanAssets = () => {
  return {
    type: RESET_LOAN_ASSETS,
  }
}

export const getPendingLoanRequests = (params = { pageNumber, pageSize }) => {
  return async (dispatch, state) => {

    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      dispatch(uiStartLoading())
      const url = baseUrl + `RetailAccessValidation/GetAllPendingRequests`
      const res = await axios.get(url, {
        params,
        headers: {
          Authorization: `Bearer ${state.auth.tokenData.token}`
        }
      })

      if (res.status === 200 && res.data.code === "00") {
        dispatch(setPendingLoanAssets(res.data))
        dispatch(uiStopLoading())
        return null
      }
      dispatch(uiStopLoading())
      return res.data?.description
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(logOut())
      }
      if (error?.response?.data?.description) {
        return error.response.data.description
      }
      return ERROR_MESSAGE
    }
  }

}

export const getLoanAssetsRequest = (data, params = { pageNumber, pageSize }) => {
  return async (dispatch, state) => {
    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      dispatch(uiStartLoading())
      const url = baseUrl + `RetailAccessValidation/GetRequests`
      const res = await axios.get(url, {
        params: { ...params, AccountNumber: data.accountNumber, IntegrifyId: data.integrifyID, requestedBy: state.auth.tokenData.staffName },
        headers: {
          Authorization: `Bearer ${state.auth.tokenData.token}`
        }
      })

      if (res.status === 200 && res.data.code === "00") {
        dispatch(setLoanAssetRequest(res.data))
        dispatch(uiStopLoading())
        return null
      }
      dispatch(uiStopLoading())
      return res.data?.description
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(logOut())
      }
      dispatch(uiStopLoading())
      if (error?.response?.data?.description) {
        return error.response.data.description
      }
      return ERROR_MESSAGE
    }
  }

}


export const getLoanAssetsReport = (data, params = { pageNumber, pageSize }) => {
  return async (dispatch, state) => {
    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      dispatch(uiStartLoading())
      const url = baseUrl + `RetailAccessValidation/GetReports`
      const res = await axios.get(url, {
        params: { ...params, startDate: data.startDate, endDate: data.endDate, Integrify: data.Integrify, requestedBy: state.auth.tokenData.staffName },
        headers: {
          Authorization: `Bearer ${state.auth.tokenData.token}`
        }
      })
      if (res.status === 200 && res.data.code === "00") {
        dispatch(setLoanAssetsReport(res.data))
        dispatch(uiStopLoading())
        return null
      }
      dispatch(uiStopLoading())
      return res.data?.description

    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(logOut())
      }
      dispatch(uiStopLoading())
      if (error?.response?.data?.description) {
        return error.response.data.description
      }
      return ERROR_MESSAGE
    }
  }
}

export const uploadLoanAsset = (data) => {
  return async (dispatch, state) => {

    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      dispatch(uiStartLoading())
      const url = baseUrl + 'RetailAccessValidation/AddRetaillAccessValidation'
      const token = state.auth.tokenData.token
      const res = await axios.post(url, { ...data }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (!res.status === 200 && !res.data.code === "00") {
        dispatch(uiStopLoading())
        return res.data?.description
      }
      dispatch(uiStopLoading())
      return null
    } catch (error) {
      dispatch(uiStopLoading())
      if (error?.response?.status === 401) {
        dispatch(logOut())
      }
      if (error?.response?.status === 400) {
        return error.response.data.description
      }
      return ERROR_MESSAGE
    }
  }
}


export const approveRequest = (data) => {
  return async (dispatch, state) => {
    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      dispatch(uiStartLoading())
      const url = baseUrl + `RetailAccessValidation/ApproveRequest`
      const res = await axios.put(url, { ...data, approvedBy: state.auth.tokenData.staffName }, {
        headers: {
          Authorization: `Bearer ${state.auth.tokenData.token}`
        }
      })
      if (res.status === 200 && res.data.code === "00") {
        dispatch(uiStopLoading())
        dispatch(getPendingLoanRequests())
        return null
      }
      dispatch(uiStopLoading())
      return res.data?.description
    } catch (error) {
      dispatch(uiStopLoading())
      if (error?.response?.status === 401) {
        dispatch(logOut())
      }

      if (error?.response?.data?.description) {
        return error.response.data.description
      }

      return ERROR_MESSAGE
    }
  }
}

