import { removeAsyncData } from '../../helpers'

export {login, setLocalAuthData, logOut, verifyToken, setBrowserXframeOption} from './auth'
export {getActivitiesData, getChartData} from './statistics'
export  { uploadLoanAsset, getLoanAssetsReport, getPendingLoanRequests, approveRequest , resetLoanAssets, getLoanAssetsRequest} from './loanActions'
export {addUser, getUsers, getUserRoles, updateUserRole} from './userActions'
export {uiStartLoading, uiStopLoading} from './uiActions'

export const resetApp = ()=> {
  removeAsyncData()
  window.location.reload()
} 