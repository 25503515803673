import React from 'react'
import ReactDOM from 'react-dom'
import StoreProvider from './app/store/storeProvider';
// Redux
// https://github.com/rt2zz/redux-persist
import { Chart, registerables } from 'chart.js'

// Apps
import { App } from './app/App'
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import './_metronic/assets/sass/style.scss'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
Chart.register(...registerables)

ReactDOM.render(
  <StoreProvider>
    <MetronicI18nProvider>
      <App basename={PUBLIC_URL} />
    </MetronicI18nProvider>
  </StoreProvider>,
  document.getElementById('root')
)
