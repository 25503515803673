import { baseUrl } from "../../constants"
import axios from '../../config/axios.config'
import { ERROR_MESSAGE, TIME_OUT_MESSAGE, pageNumber, pageSize } from "../../constants"
import { logOut } from "./"
import { SET_ROLES, SET_USERS } from "./actionTypes"
import { uiStartLoading, uiStopLoading } from "./uiActions"


const setUsers = (payload) => {
  return {
    type: SET_USERS,
    payload
  }
}


const setRoles = (payload) => {
  return {
    type: SET_ROLES,
    payload
  }
}

export const addUser = (data) => {
  return async (dispatch, state) => {
    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      dispatch(uiStartLoading())
      const url = baseUrl + 'RetailAccessValidation/CreateUser'
      const res = await axios.post(url, { ...data }, {
        headers: {
          Authorization: `Bearer ${state.auth.tokenData.token}`
        }
      })
      if (!res.status === 200 && !res.data.code === "00") {
        dispatch(uiStopLoading())
        return ERROR_MESSAGE
      }
      dispatch(uiStopLoading())
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(logOut())
      }

      dispatch(uiStopLoading())

      if (error?.response?.data?.description) {
        return error.response.data.description
      }
      return ERROR_MESSAGE
    }
  }
}


export const getUsers = (params = { pageNumber, pageSize }) => {
  return async (dispatch, state) => {
    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      dispatch(uiStartLoading())
      const url = baseUrl + `RetailAccessValidation/GetAllUsers`
      const res = await axios.get(url, {
        params,
        headers: {
          Authorization: `Bearer ${state.auth.tokenData.token}`
        }
      })
      if (res.status === 200 && res.data.code === "00") {
        await dispatch(setUsers(res.data))
        dispatch(uiStopLoading())
        return null;
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(logOut())
      }
      dispatch(uiStopLoading())
      if (error?.response?.data?.description) {
        return error.response.data.description
      }
      return ERROR_MESSAGE
    }
  }
}


export const getUserRoles = () => {
  return async (dispatch, state) => {
    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      const url = baseUrl + `RetailAccessValidation/GetUserRoles`
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${state.auth.tokenData.token}`
        }
      })

      if (res.status === 200 && res.data.code === "00") {
        dispatch(setRoles(res.data.data))

        return null
      }

    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(logOut())
      }
      return ERROR_MESSAGE
    }
  }

}

export const updateUserRole = (data) => {
  return async (dispatch, state) => {
    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      dispatch(uiStartLoading())
      const url = baseUrl + 'RetailAccessValidation/UpdateUserRole'
      const res = await axios.put(url, { ...data },  {
        headers: {
          Authorization: `Bearer ${state.auth.tokenData.token}`
        }
      })
      if (!res.status === 200 && !res.data.code === "00") {
        dispatch(uiStopLoading())
        return ERROR_MESSAGE
      }
      dispatch(uiStopLoading())
      dispatch(getUsers())
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(logOut())
      }
      dispatch(uiStopLoading())
      if (error?.response?.data?.description) {
        return error.response.data.description
      }
      return ERROR_MESSAGE
    }
  }
}